import React from 'react';
import {Form, Layout, Menu} from 'antd';
import {Link, Route, useLocation, useHistory} from 'react-router-dom'
import Dashboard from "./views/dashboard";
// import Shops from "./views/barbers";
import Shops_Page from "./views/shops";
import "antd/dist/antd.css";
import Localforage from "localforage";
import {Get_Barbers_Admin} from "./apis";
import Toast_noty from "./components/toasty_notification";
import {FileTextOutlined, AppstoreOutlined, UserOutlined, LogoutOutlined, ShopOutlined , MailOutlined} from "@ant-design/icons"
import Barbers_Page from "./views/barbers";
import All_reserves from "./views/all_reserves";
import Contact from './views/contact';


const BaseLayout = (props) => {
    const LocationPath = useLocation().pathname;
    const Routing = useHistory();
    const {Header, Content, Sider, Footer} = Layout;
    const [collapsed, setCollapsed] = React.useState(true);
    const [logo_appear, setLogo_appear] = React.useState("none");
    const [is_admin, setIs_admin] = React.useState(false);
    const [name, setName] = React.useState("");


    React.useEffect(() => {
    }, [])


    return (
        <Layout style={{minHeight: '100vh'}} className={"base_sider"}>
            <Layout.Sider
                theme={"light"}
                collapsible
                collapsed={collapsed}
                onCollapse={(collapsed) => {
                    setCollapsed(collapsed);
                    if (collapsed) {
                        setLogo_appear("none")
                    } else {
                        setLogo_appear("block")
                    }
                }}
            >
                <div className={`logo_base_container`}>
                </div>
                <Menu theme="dark" mode="inline">

                    <Menu.Item icon={<AppstoreOutlined/>} onClick={() => Routing.push("/base/dashboard")}>
                        dashboard
                    </Menu.Item>

                    <Menu.Item icon={<ShopOutlined/>} onClick={() => Routing.push("/base/shops")}>
                        Shops
                    </Menu.Item>

                    <Menu.Item icon={<UserOutlined/>} onClick={() => Routing.push("/base/barbers")}>
                        barbers
                    </Menu.Item>

                    <Menu.Item icon={<FileTextOutlined/>} onClick={() => Routing.push("/base/all-reserves")}>
                        all reserves
                    </Menu.Item>

                    <Menu.Item icon={<MailOutlined />} onClick={() => Routing.push("/base/contact")}>
                        Contact
                    </Menu.Item>

                    <Menu.Item
                        icon={<LogoutOutlined/>}
                        onClick={() => {
                            Localforage.removeItem("token");
                            Localforage.removeItem("i_a");
                            Routing.push("/");
                        }}
                    >
                        <div>Logout</div>
                    </Menu.Item>

                </Menu>
            </Layout.Sider>
            <Layout>
                <Content className="content_base_layout">
                    <div style={{padding: "2rem"}}>
                        <Header style={{marginBottom: "3rem"}} className="layout-header">
                            <h3>chalee barber</h3>
                            <small> Main Admin : {name}</small>

                        </Header>
                        {props.children}
                        <Route path='/base/dashboard' component={Dashboard}/>
                        <Route path='/base/shops' component={Shops_Page}/>
                        <Route path='/base/barbers' component={Barbers_Page}/>
                        <Route path='/base/all-reserves' component={All_reserves}/>
                        <Route path='/base/contact' component={Contact}/>

                    </div>
                    <Footer style={{textAlign: 'center'}}>chalee.barber © 2022</Footer>
                </Content>
            </Layout>
        </Layout>
    )
}

export default BaseLayout;