
export const BaseUrl = "https://api.barberssite.com";
export const ImageUrl = "https://api.barberssite.com";
export const SiteUrl = "https://barberssite.com";


// export const BaseUrl = "http://192.168.1.101:9898";
// export const ImageUrl = "http://192.168.1.101:9898";

async function Fetch(url, method, bodyReq, token = "") {
    if (method === "GET" || method === "HEAD") {
        const Response = await fetch(`${BaseUrl}${url}`, {
            mode: 'cors',
            method: method,
            headers: token !== "" ?
                {'token': `${token}`, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
                :
                {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        });
        return Response.json();
    } else {
        const Response = await fetch(`${BaseUrl}${url}`, {
            mode: 'cors',
            method: method,
            body: bodyReq,
            headers: token !== "" ?
                {'token': `${token}`, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
                :
                {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        });
        return Response.json();
    }
}

async function Fetch_Form(url, method, bodyReq, token = "") {
    const Response = await fetch(`${BaseUrl}${url}`, {
        mode: 'cors',
        method: method,
        body: bodyReq,
        headers: {'token': `${token}`, 'Access-Control-Allow-Origin': '*'}
    });
    return Response.json();
}


export const Get_Barbers = () => {
    return Fetch("/barber", "GET", "", "")
}


export const Get_Barbers_all_admin = (MainAdminToken) => {
    return Fetch("/barber/all", "GET", "", MainAdminToken)
}

export const Get_Barbers_Admin = (name) => {
    return Fetch(`/barber/shop/admin/${name}`, "GET", "", "")
}


export const Get_Shops_Admin = (name) => {
    return Fetch(`/shop/admin`, "GET", "", "")
}

export const Sign_In = (val) => {
    const post_data = {
        email: val.email,
        password: val.password
    }
    return Fetch("/barber/login", "POST", JSON.stringify(post_data), "")
}


export const Add_New_Barbers = (body, token) => {
    return Fetch("/barber", "POST", JSON.stringify(body), token)
}


export const Add_Image_Barbers = (body, barber, token) => {
    return Fetch_Form(`/barber/profile/${barber}`, "PUT", body, token)
}


export const Add_Image_Shops= (body, barber, token) => {
    return Fetch_Form(`/shop/profile/${barber}`, "PUT", body, token)
}



export const Remove_Barbers = (id, token) => {
    return Fetch(`/barber/${id}`, "DELETE", "", token)
}

export const Get_Barber_List_Time = (id) => {
    return Fetch(`/reserve/listReserve/barber/${id}`, "GET", "", "")
}


export const Add_List_Time = (barberId, data, token) => {
    return Fetch(`/barber/edit/${barberId}`, "PUT", JSON.stringify(data), token)
}


export const Get_all_reserve_for_admin = (admin_token) => {
    return Fetch(`/reserve/shop`, "GET", '', admin_token)
}


export const Get_all_reserve_for_Main_Admin = (admin_token) => {
    return Fetch(`/reserve`, "GET", '', admin_token)
}


export const Get_Barber_Reserve = (barber_token, barber) => {
    return Fetch(`/reserve/barber/${barber}`, "GET", '', barber_token)
}


export const Edit_Reserve = (id, body, token, barber_id) => {
    return Fetch(`/reserve/barber/${barber_id}/reserve/${id}/`, "PUT", body, token)
}


export const Delete_Reserve = (id, token, barber_id) => {
    return Fetch(`/reserve/barber/${barber_id}/reserve/${id}`, 'DELETE', '', token)
}


export const Post_New_Reserve = (body) => {
    return Fetch(`/reserve`, "POST", body, "")
}


export const Block_Times = (body, id, token) => {
    return Fetch(`/reserve/timeBlock/barber/${id}`, "POST", body, token)
}


export const Barber_active_de_active = (id, body, token) => {
    return Fetch(`/barber/status/${id}`, "PUT", JSON.stringify(body), token)
}


export const Shop_active_de_active = (id, body, token) => {
    return Fetch(`/shop/status/${id}`, "PUT", JSON.stringify(body), token)
}


export const Get_all_contact_for_Main_Admin = (admin_token) => {
    return Fetch(`/contact`, "GET", '', admin_token)
}


export const Delete_Contact = (token, contact_id) => {
    return Fetch(`/contact/${contact_id}`, 'DELETE', '', token)
}
