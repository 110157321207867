import {BrowserRouter as Router, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import React from "react";
import Login_Page from "./views/login";
import BaseLayout from "./base_layout";

function App() {
    return (
        <div className="App">
            <Router>
                <Route exact path='/' component={Login_Page}/>
                <Route exact path='/login' component={Login_Page}/>
                <Route path='/base' component={BaseLayout}/>
            </Router>
            <ToastContainer position="top-right"
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme={'dark'}
            />
        </div>
    );
}

export default App;
