import React from "react";
import {
    Add_New_Barbers,
    Get_Barbers_Admin,
    Get_Shops_Admin,
    ImageUrl,
    Remove_Barbers,
    Barber_active_de_active,
    Shop_active_de_active,
    BaseUrl,
    Add_Image_Barbers, Add_Image_Shops
} from "../apis";
import Localforage from "localforage";
import Toast_noty from "../components/toasty_notification";
import Skeleton from "react-loading-skeleton";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Tooltip, Modal, Form, Button, Upload, Popconfirm} from 'antd';
import {
    UploadOutlined,
    PlusOutlined,
    EyeOutlined,
    DeleteFilled,
    ArrowDownOutlined,
    ArrowUpOutlined, LikeOutlined, DislikeOutlined
} from "@ant-design/icons";


export default function Shops_Page() {
    const [barbers, setBarbers] = React.useState([]);
    const [skeleton_loading, setSkeleton_loading] = React.useState(true);
    const [add_barber_modal, setAdd_barber_modal] = React.useState(false);
    const [image_upload_modal, setImage_upload_modal] = React.useState(false);
    const [add_Package_form] = Form.useForm();
    const [button_loading, setButton_loading] = React.useState(false);
    const [selected_barber, setSelected_barber] = React.useState({});

    const [imageList, setImageList] = React.useState([]);

    const onChangeImage = ({fileList: newFileList}) => {
        setImageList(newFileList);
    };
    const onPreviewImage = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const dummyRequestImage = ({file, onSuccess}) => {
        onSuccess("ok");
    };


    React.useEffect(() => {
        Get_Barbers_API();
    }, []);

    async function Get_Barbers_API() {
        setSkeleton_loading(true);
        const Login_Rec = Get_Shops_Admin();
        Login_Rec.then((data) => {
            setSkeleton_loading(false);
            if (data.result) {
                setBarbers(data.data);
            } else {
                return Toast_noty(data.mes, 5000, "error")
            }
        })
    }


    function close_modal() {
        setAdd_barber_modal(false);
        add_Package_form.resetFields();
    }

    function close_modal_IMG_Upload() {
        setImage_upload_modal(false);
        setImageList([]);
    }

    async function Add_Barber_API(value) {
        const token = await Localforage.getItem('token', async function (err, value) {
            return value
        });
        const Add_New_Barbers_Rec = Add_New_Barbers(value, token);
        Add_New_Barbers_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Barbers_API();
                Toast_noty("new barber is created", 5000, "success");
                close_modal();
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }
    //
    // async function Add_Image_API() {
    //     if(imageList.length === 0){return Toast_noty("image is required  (:", 5000, "warning");}
    //     const token = await Localforage.getItem('token', async function (err, value) {
    //         return value
    //     });
    //     setButton_loading(true);
    //     let data = new FormData();
    //     data.append('image', imageList[0].originFileObj);
    //     const Add_New_Barbers_Rec = Add_Image_Shops(data, selected_barber._id, token);
    //     Add_New_Barbers_Rec.then((data) => {
    //         setButton_loading(false);
    //         if (data.result) {
    //             Get_Barbers_API();
    //             Toast_noty("Image Updated", 5000, "success");
    //             close_modal_IMG_Upload();
    //         } else {
    //             Toast_noty(data.mes, 5000, "error");
    //         }
    //     })
    // }


    async function Barber_active_status_API(id, status) {
        setSkeleton_loading(true);
        const token = await Localforage.getItem('token', async function (err, value) {
            return value
        });
        const body = {
            isActive: status
        }
        const Barber_active_status_Rec = Shop_active_de_active(id, body, token);
        Barber_active_status_Rec.then((data) => {
            if (data.result) {
                Get_Barbers_API();
                Toast_noty("barber status is updated successfully", 5000, "success");
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }


    return (
        <>
            <div className={`barbers_co_container`}>
                <div onClick={() => {
                    setAdd_barber_modal(true)
                }} style={{cursor: "pointer"}} className={`barbers_item_container add_barber_item_container`}>
                    <PlusOutlined/>
                    <h4>create new shop</h4>
                </div>
                {skeleton_loading ?
                    <>
                        {barbers.map((data) =>
                            <div className={`barbers_item_container`}>
                                <Skeleton style={{borderRadius: "25px", width: "100%"}} height={350}/>
                            </div>
                        )}
                    </>
                    :
                    <>
                        {barbers.map((data) =>
                            <>
                                <div className={`barbers_item_container`}>
                                    {data.image ? <LazyLoadImage
                                            alt={data._id.slice(-5)}
                                            effect="blur"
                                            placeholder={<Skeleton style={{borderRadius: "25px", width: "100%"}}
                                                                   height={250}/>}
                                            src={`${ImageUrl}/shop/${data.image}`}
                                        /> :
                                        <>
                                            {/*<Tooltip title={`click to add Image`}>*/}
                                                <div className={`add_img_div`} onClick={() => {
                                                    // setSelected_barber(data)
                                                    // setImage_upload_modal(true)
                                                }}>
                                                    <h4>no-image</h4>
                                                    <Skeleton style={{width: "100%"}} height={250}/>
                                                </div>
                                            {/*</Tooltip>*/}
                                        </>
                                    }

                                    <h4>{data.shopName}{" "}<br/>
                                        {data.isActive ? '' : <small style={{color: "#f50"}}>( de-active )</small>}
                                    </h4>
                                    <h5>subdomain: {data.subdomain}</h5>
                                    <div className={`product_options_todo`}>
                                        <Tooltip title={`${data.isActive ? "de-active" : "active"}`}>
                                            <div onClick={() => {
                                                Barber_active_status_API(data._id, !data.isActive)
                                            }} className={`product_options_item`}>
                                                {data.isActive ?
                                                    <LikeOutlined  style={{color: "#5eff00"}}/> :
                                                    <DislikeOutlined style={{color: "#f50"}}/>
                                                }
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="show in web">
                                            <a
                                                target={"_blank"}
                                                href={`http://localhost:4000/bs/${data.subdomain}`}
                                                className={`product_options_item`}>
                                                <EyeOutlined/>
                                            </a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                }
            </div>
            <Modal
                title="create new barber"
                style={{top: 20}}
                visible={add_barber_modal}
                footer={null}
                onCancel={() => close_modal()}
            >
                <Form
                    requiredMark={false}
                    form={add_Package_form}
                    name="register"
                    onFinish={Add_Barber_API}
                    scrollToFirstError
                    style={{marginTop: "1rem", width: "100%", textAlign: "center"}}
                >
                    <label className={`label_input`} htmlFor="title">full name</label>
                    <Form.Item
                        name="fullName"
                        rules={[{required: true, message: "insert full name"}]}
                    >
                        <input className={`_input`} placeholder={"insert full name"}/>
                    </Form.Item>

                    <label className={`label_input`} htmlFor="Email">Email</label>
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: "insert email", type: "email"}]}
                    >
                        <input className={`_input`} placeholder={"insert email"}/>
                    </Form.Item>

                    <label className={`label_input`} htmlFor="prepayment">password</label>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: "insert password"}]}
                        help={"insert an strong password"}
                    >
                        <input className={`_input`} placeholder={"insert password"}/>
                    </Form.Item>

                    <Button loading={button_loading} className={`overall_button sign_in_button`} htmlType="submit">
                        + create
                    </Button>
                </Form>
            </Modal>
            {/*<Modal*/}
            {/*    title="Image Upload"*/}
            {/*    style={{top: 20}}*/}
            {/*    visible={image_upload_modal}*/}
            {/*    footer={null}*/}
            {/*    onCancel={() => close_modal_IMG_Upload()}*/}
            {/*>*/}
            {/*    <Upload listType="picture-card"*/}
            {/*            action=''*/}
            {/*            fileList={imageList}*/}
            {/*            onChange={onChangeImage}*/}
            {/*            onPreview={onPreviewImage}*/}
            {/*            accept=".png,.jpg,.jpeg,.webp"*/}
            {/*            customRequest={dummyRequestImage}>*/}
            {/*        {imageList.length < 1 && <div className={`column_`}><UploadOutlined/><small>add image</small></div>}*/}
            {/*    </Upload>*/}
            {/*    <div className={`column_`}>*/}
            {/*        {imageList.length > 0 &&*/}
            {/*        <Button onClick={()=>{*/}
            {/*            Add_Image_API()*/}
            {/*        }} loading={button_loading} className={`overall_button sign_in_button`}> submit upload*/}
            {/*        </Button>}*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </>
    )
}