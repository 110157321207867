import React from "react";
import {Link} from "react-router-dom";
import Localforage from "localforage";
import {FileTextOutlined,ShopOutlined, UserOutlined, MailOutlined} from "@ant-design/icons"


export default function Dashboard() {
    const [is_admin, setIs_admin] = React.useState(false);


    React.useEffect(()=>{
    },[])

    return (
        <>
            <h2>Hello, welcome to your dashboard</h2>
            <div className={`dashboard_container`}>
                <Link to={"/base/shops"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <ShopOutlined />
                        <h4>mange shops</h4>
                    </div>
                </Link>
                <Link to={"/base/barbers"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <UserOutlined/>
                        <h4>mange barbers</h4>
                    </div>
                </Link>
                <Link to={"/base/all-reserves"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <FileTextOutlined />
                        <h4>all shops reserves</h4>
                    </div>
                </Link>
                <Link to={"/base/contact"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <MailOutlined />
                        <h4>contact</h4>
                    </div>
                </Link>
            </div>
        </>
    )
}