import React from "react";
import Localforage from "localforage";
import {Delete_Contact, Get_all_contact_for_Main_Admin} from "../apis";
import { Button, Popconfirm} from 'antd'
import Toast_noty from "../components/toasty_notification";
import Skeleton from "react-loading-skeleton";
import {DeleteOutlined } from "@ant-design/icons"


export default function Contact() {
    const [barberId, setBarber_id] = React.useState("");
    const [barberToken, setBarberToken] = React.useState("");
    const [contact_data, setContact_data] = React.useState([]);
    const [key_search, setKey_search] = React.useState("");
    const [skeleton_loading, setSkeleton_loading] = React.useState(false);



    async function Get_Reserve_API() {
        const token = await Localforage.getItem('token', async function (err, value) {
            return value
        });
        const Get_reserve_Rec = Get_all_contact_for_Main_Admin(token);
        Get_reserve_Rec.then((data) => {
            if (data.result) {
                setContact_data(data.data.reverse())
            } else {
                Toast_noty("Access denied", 5000, "warning");
            }
        })
    }

    React.useEffect(async ()=>{
        const token = await Localforage.getItem('token', async function (err, value) {
            return value
        });
        const barber_id = await Localforage.getItem('id', async function (err, value) {
            return value
        });
        setBarberToken(token)
        setBarber_id(barber_id)
        Get_Reserve_API();
    },[])



    async function DELETE_Contact_API(contact_id) {
        setSkeleton_loading(true);
        const token = await Localforage.getItem('token', async function (err, value) {
            return value
        });
        const DELETE_Reserve_Rec = Delete_Contact(token, contact_id);
        DELETE_Reserve_Rec.then((data) => {
            setSkeleton_loading(false);
            if (data.result) {
                Get_Reserve_API()
                Toast_noty("Removed Successfully", 5000, "success");
            } else {
                Toast_noty("Error accrued", 5000, "error");
            }
        })
    }

    return (
        <>
            <div className={`all_reserve_container`}>

                {contact_data.length !== 0 ? contact_data.map((data) =>
                    <div className={`all_reserve_items_container`}>
                        <p>first name : {data.firstName ? data.firstName : ""}</p>
                        <p>last name : {data.lastName ? data.lastName : ""}</p>
                        <p>topic : {data.topic ? data.topic : ""}</p>
                        <p>contact Preference : {data.contactPreference ? data.contactPreference : ""} </p>
                        <p>phone : {data.phone ? data.phone : ""}</p>
                        <p>email : {data.email ? data.email : ""}</p>
                        <p>comment : {data.comment ? data.comment : ""}</p>
                        <p>zipCode : {data.zipCode ? data.zipCode : ""}</p>
                        <p>region : {data.region ? data.region : ""}</p>
                        <div className={`product_options_todo`}>
                            <Popconfirm
                                title={'sure?'}
                                onConfirm={() => {DELETE_Contact_API(data._id)}} okText="yes" cancelText="no"
                            >
                                <Button loading={skeleton_loading} className={`confirm_butt confirm_butt_delete`}>remove <DeleteOutlined /></Button>
                            </Popconfirm>
                        </div>
                    </div>
                ):  <Skeleton style={{borderRadius: "25px", width: "250"}} height={350}/>}
            </div>
        </>
    )
}