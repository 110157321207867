import React from "react";
import {useHistory} from "react-router-dom";
import {Form, Button} from "antd";
import Toast_noty from "../components/toasty_notification";
import {Sign_In} from "../apis";
import Localforage from "localforage";


export default function Login_Page(){

    const Routing = useHistory();
    const [login_form] = Form.useForm();
    const [signIn_button, setSignIn_button] = React.useState(false);



    function submit_SignIn(val) {
        setSignIn_button(true);
        const Login_Rec = Sign_In(val);
        Login_Rec.then((data) => {
            setSignIn_button(false);
            if (data.result) {
                if(data.data.barber.isMainAdmin){
                    Localforage.setItem("i_m_a",true)
                    Localforage.setItem("token",data.token)
                    Localforage.setItem("id",data.id)
                    Localforage.setItem("name",data.name)
                    Localforage.setItem("data",data.data)
                    Toast_noty("success login", 5000, "success");
                    setTimeout(()=>{
                        Routing.push("/base/dashboard");
                    },500)
                }else {
                    return Toast_noty("You are not Platform Owner (Main Admin)", 5000, "error");
                }
            } else {
                return Toast_noty("invalid password or username", 5000, "error")
            }
        })
    }


    return(
        <>
            <div className={"sign_in_container"}>
                <h1>Owner Platform's Panel</h1>
                <Form
                    className={"add_barber_modal"}
                    requiredMark={false}
                    form={login_form}
                    name="register"
                    onFinish={submit_SignIn}
                    scrollToFirstError
                    style={{marginTop: "1rem",width: "100%", textAlign:"center"}}
                >
                    <label className={`label_input`} htmlFor="email">UserName<small>(Email)</small></label>
                    <Form.Item
                        style={{marginBottom: "1rem"}}
                        name="email"
                        rules={[{required: true, message: "insert your Email"}]}
                    >
                        <input className={`_input`} placeholder={"insert your username (Email)"}/>
                    </Form.Item>

                    <label className={`label_input`} type={"password"} htmlFor={"password"}>password</label>
                    <Form.Item
                        style={{marginBottom: "1rem"}}
                        name="password"
                        rules={[{required: true, message: "insert password"}]}
                    >
                        <input type={"password"} className={`_input`} placeholder={"insert password"}/>
                    </Form.Item>

                    <Button loading={signIn_button} className={`overall_button sign_in_button`} htmlType="submit">Submit Login</Button>
                </Form>
                <footer className={`footer`}>
                    <a>Chalee.Barber © 2022</a>
                </footer>
            </div>
        </>
    )
}